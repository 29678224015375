import React from 'react';
import '../index.css';

// TODO: Animate on hover background color change

class Button extends React.Component {

    constructor(props){
        super(props);
        this.props = props;

        this.styles = "text-gray-700 font-roboto border border-gray-400 text-2xl mr-4 mt-2 cursor-pointer rounded-lg py-0 px-2 hover:bg-gray-200";
    }

    render() {
        let element = (
            <button className={this.styles + " " + this.props.className}>
                {this.props.text}
            </button>
        );

        return element;
    }
}

export default Button;