import React from 'react';

import '../index.css';
// import FingerprintSVG from '../assets/images/fingerprint.svg';
import AuthenticationSVG from '../assets/images/authentication.svg';
import '../components/Button';
import Button from '../components/Button';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.onWhiteHead = {
            color: "rgb(66,80,102)"
        };
        this.styles = {
            height: "100vh"
        };
        this.landingText = "font-work-sans font-semibold text-white text-4xl lg:text-6xl antialiased";

        this.loginCard = {
            "margin-top": "50vh",
            transform: "translateY(-50%)"
        };

    }

    render() {
        const element = (
            <div className="flex flex-col xl:flex-row">
                <div style={this.styles} className="w-full xl:w-5/12 bg-gray-800">
                    <div className="ml-6 pt-12">
                        <div className={this.landingText}>Certificate</div>
                        <div className={this.landingText}>Authentication</div>
                        <div className={this.landingText}>System</div>
                    </div>
                    <img className="w-5/12 mx-auto mt-24" src={AuthenticationSVG} />
                    <div className="absolute bottom-0 text-sm text-white ml-4 mb-4">
                        Powered by the Royal College Computer Society
            </div>
                </div>

                <div style={this.styles} className="w-full w-7/12 bg-white h-full">
                    <div className="max-w-md shadow-md rounded-lg mx-auto clearfix" style={this.loginCard}>
                        <div className="m-2 pt-4 px-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                                Username
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="JaneDoe"></input>
                            <label class="block text-gray-700 text-sm font-bold mb-2 mt-4" for="password">
                                Password
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="********"></input>
                        </div>
                        <Button className="float-right mb-2" text="Login" />
                    </div>
                </div>
            </div>
        );

        return (element);
    }
}

export default LoginPage;