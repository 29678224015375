import React from 'react';

import LoginPage from './pages/LoginPage';

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <LoginPage />
        );
    }
}

export default App;